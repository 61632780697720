<template>
  <div id="home">
    <!-- header  -->
    <WaBtn />
    <!-- <MainHeader /> -->

    <!-- main banner  -->
    <MainBanner />

    <!-- skyline kuala lumpur  -->
    <!-- <div class="py-20">
      <div class="container">
        <div
          class="text-xl leading-tight text-center lg:text-2xl text-sky-blue"
        >
          <h1 class="text-3xl font-semibold lg:text-5xl">
            Welcome to
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r from-sky-gold to-sky-yellow"
              >Skyline Kuala Lumpur</span
            >
          </h1>
          <div class="h-0.5 w-2/3 lg:w-96 mx-auto my-8 bg-sky-blue"></div>
          <p class="pb-3">
            Living in such close proximity to a myriad of popular landmarks and
            designation make for a truly convenient and enriched life.
          </p>
          <p>A most astute investment.</p>
        </div>
      </div>
    </div> -->
    <!-- about us  -->
    <div class="py-10">
      <div class="container">
        <div class="lg:flex">
          <!-- left side  -->
          <div class="lg:w-1/2 lg:px-1">
            <img
              src="/images/main-logo.png"
              alt=""
              class="mx-auto w-52 md:w-72"
            />
            <p class="pt-5 text-sm text-gray-600">
              Welcome to Skyline KL @ Pudu. The finest urban living experiences
              in Kuala Lumpur. Situated at the very heart of the pulsating
              metropolis, residents can experience the best modern lifestyle,
              closely connected to a variety of popular destinations and
              amenities.
            </p>
            <div class="pt-5">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/HESNPDaPSuQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <p class="pt-5 text-sm text-gray-600">
              Living in such close proximity to a myriad of popular landmarks
              and destinations makes for a truly convenient and enriched life.
              <br /><br />
              Practical unit sizes from 480sf to 1,080 with cleverly designed
              open spaces, you can tailor your home to suit your style in any
              way you please.
            </p>
          </div>

          <!-- right side  -->
          <div class="pt-5 lg:pt-0 lg:px-2 lg:w-1/2">
            <div
              class="px-5 py-8 text-left border-2 shadow-lg md:w-2/3 lg:w-full lg:mx-0 md:mx-auto text-sky-gold rounded-tr-2xl round-tr rounded-bl-2xl border-sky-gold"
            >
              <h1 class="text-3xl font-light lg:text-5xl">
                <span class="font-bold">Register</span> Your <br />
                Interest Today
              </h1>
              <p class="pt-4 text-base">
                Get E-Brochure, Attractive Pricing and Your Exclusive Promotion. Special For You Only! 
              </p>
              
              <div class="pt-4">
                <EnquiryForm />
                <div class="pt-4">
                  <h1
                    class="flex items-center justify-center p-2 text-center border-2 rounded-full md:w-60 border-sky-gold"
                  >
                    <a href="https://wa.me/60192288230">Whatsapp Us </a>
                    <i
                      class="pl-2 text-3xl text-green-500 fa-brands fa-whatsapp-square"
                    ></i>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end about us  -->

    <!-- sub-section-1  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover lg:bg-bottom"
      style="background-image: url('/images/sub-section-1.jpg')"
    >
      <div class="container">
        <div class="py-20 text-left text-white md:py-32 lg:py-44 textshadow">
          <h1 class="text-2xl font-bold md:text-4xl lg:text-5xl">ALWAYS CONNECTED</h1>
          <p class="text-lg font-medium leading-tight lg:text-2xl">
            FINEST URBAN LIVING EXPERIENCES
          </p>
        </div>
      </div>
    </div>
    <!-- end sub-secton -1  -->

    <!-- Unique Features -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-features.jpg')"
    >
      <div class="container">
        <!-- <h1 class="text-2xl font-semibold text-white">
          Unique Features for <span class="text-sky-gold">Skyline</span>
        </h1>
        <div class="h-0.5 w-14 mx-auto my-8 bg-white"></div> -->
        <div class="">
          <img src="/images/main-logo.png" alt="" class="w-2/3 mx-auto md:w-72" />
          <h1 class="pt-5 text-base font-bold md:text-4xl text-sky-gold">
            WHY SKYLINE KUALA LUMPUR?
          </h1>
          <p class="text-sm text-white">A MOST ASTUTE INVESTMENT</p>
        </div>

        <div class="lg:flex lg:pt-8">
          <div class="flex flex-wrap pt-5 lg:w-2/3 md:justify-center">
            <div
              v-for="(item, i) in unique"
              :key="i"
              class="w-1/2 pb-5 leading-tight text-white md:w-1/4 lg:w-1/5"
            >
              <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
              <h1 class="px-2 pt-3 text-sm leading-tight lg:px-1">{{ item.alt }}</h1>
            </div>
          </div>
          <div class="pt-5 lg:pt-0 md:w-1/3 md:mx-auto lg:mx-0">
            <div class="relative">
              <img src="/images/bg-gray.png" alt="" class="mx-auto lg:w-full" />
              <div class="overlay">
                <h1 class="text-4xl font-bold text-sky-gold">Time is NOW</h1>
                <p class="pt-3 text-sm font-bold text-sky-gold">
                  SCHEDULE A PROPERTY TOUR WITH <br class="hidden lg:block"> ABSOLUTELY FREE WITH NO
                  OBLIGATION.
                </p>
                <div class="pt-5">
                  <h1
                    class="p-3 mx-auto text-sm font-bold text-white uppercase transition duration-300 ease-linear rounded-full w-72 bg-sky-gold hover:bg-sky-yellow"
                  >
                    <a href="https://wa.me/60192288230"
                      >Whatsapp for appointment</a
                    >
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="flex flex-col pt-6 text-sm font-medium text-center text-gray-700 md:flex-row md:justify-center"
        >
          <a href="https://wa.me/60192288230" class="px-1 mb-4 md:mb-0">
            <h1
              class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
            >
              Book Appt To View
            </h1>
          </a>
          <a href="tel:+60192288230" class="px-1">
            <h1
              class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
            >
              Call Us Now
            </h1>
          </a>
        </div> -->
      </div>
    </div>

    <!-- SKYLINE KL FACILITIES -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-sky-blue">
          Skyline KL<span class="text-sky-gold"> Facilities</span>
        </h1>
        <div class="h-0.5 w-14 mx-auto my-8 bg-sky-blue"></div>

        <div class="flex flex-wrap pt-6">
          <div
            v-for="(item, i) in facility"
            :key="i"
            class="w-1/2 pb-5 md:w-1/5"
          >
            <h1 class="text-5xl font-semibold lg:text-7xl text-sky-gold">
              {{ item.h1 }}
            </h1>
            <p class="px-2 pt-3 text-sm leading-tight">{{ item.p }}</p>
          </div>
          <div class="pt-6">
            <div
              v-for="(item, i) in facility_2"
              :key="i"
              class="pb-6 lg:pb-8 lg:flex lg:items-center"
            >
              <img
                :src="item.image"
                :alt="item.alt"
                class="lg:w-2/3"
                :class="item.order"
              />
              <div class="text-left lg:w-1/3 lg:px-3">
                <h1
                  class="pt-5 text-3xl font-medium uppercase lg:text-4xl text-sky-gold"
                >
                  {{ item.alt }}
                </h1>
                <p class="pt-5 text-sm">
                  {{ item.p }}
                </p>
              </div>
            </div>
            <!-- <div
              class="flex flex-col pt-6 text-sm font-medium text-center text-gray-700 md:flex-row md:justify-center"
            >
              <a href="https://wa.me/60192288230" class="px-1 mb-4 md:mb-0">
                <h1
                  class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
                >
                  Book Appt To View
                </h1>
              </a>
              <a href="tel:+60192288230" class="px-1">
                <h1
                  class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
                >
                  Call Us Now
                </h1>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- sub-whatsapp  -->
    <div class="py-10 md:py-14 bg-sky-gold">
      <div class="container">
        <div class="text-white md:flex md:items-center md:justify-center">
          <h1 class="pr-2 text-xl font-bold">Make an Appointment Today?</h1>
          <div class="pt-4 md:pt-0">
            <h1
              class="py-3 mx-auto text-xs font-semibold uppercase border-2 border-white hover:opacity-90 w-72"
            >
              <a href="https://wa.me/60192288230"
                ><i class="pr-2 fa-solid fa-angles-right"></i>Whatsapp Us Now</a
              >
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- location  -->
    <div>
      <img src="/images/bg-location.jpg" alt="" />
      <div id="location" class="container">
        <div class="lg:flex lg:items-center">
          <div class="py-10 lg:w-1/3 lg:text-left text-sky-gold">
            <h1 class="text-3xl font-bold">LOCATION</h1>
            <p class="pt-4">
              Skyline KL is well positioned for a life of discovery. Its direct
              link to the Pudu LRT Station allows owners easy access to all of
              the city's most frequented destionations.
            </p>
          </div>
          <img src="/images/side-location.jpg" alt="" class="w-full lg:w-2/3" />
        </div>

        <!-- location tab  -->
        <div class="xl:max-w-4xl xl:mx-auto">
          <div id="tabs" class="pt-6 md:flex md:flex-wrap lg:justify-center">
            <div v-for="(item, i) in submenu" :key="i" class="w-full md:w-1/2 lg:w-1/4">
              <div
                v-bind:class="[tab === item.tab ? 'active' : '']"
                @click="tab = item.tab"
                class="transition duration-300 ease-linear cursor-pointer text-sky-gold hover:bg-sky-gold hover:text-white"
              >
                <h2 class="py-2 text-sm font-medium text-center">
                  <i :class="item.icon" class="pr-1 text-xl fa-solid"></i>
                  {{ item.sub }}
                </h2>
              </div>
            </div>
          </div>
          <!-- modal section -->
          <div v-for="(item, i) in Location" :key="i">
            <div v-show="tab == item.tab" class="">
              <!-- image section -->
              <img :src="item.image" alt="" class="w-full" />
            </div>
          </div>
        </div>
        <div class="px-4 pt-6 lg:flex">
          <div
            v-for="(item, i) in location"
            :key="i"
            class="pb-6 lg:w-1/3 lg:px-1"
          >
            <h1 class="p-3 font-medium text-white bg-sky-blue">
              {{ item.h1 }}
            </h1>
            <ul class="p-4 text-left text-gray-600 bg-gray-200 lg:h-52">
              <li
                v-html="list"
                v-for="list in item.select"
                :key="list"
                class="pb-1"
              >
                {{ list }}
              </li>
            </ul>
          </div>
        </div>
        <!-- end location tab  -->
      </div>
    </div>
    <!-- end location  -->

    <!-- sub-whatsapp  -->
    <div class="py-10 bg-sky-gold">
      <div class="container">
        <div class="text-white md:flex md:items-center md:justify-center">
          <h1 class="text-xl font-bold md:pr-2">Get The Exclusive Promotion Now</h1>
          <div class="pt-4 md:pt-0">
            <h1
              class="py-3 mx-auto text-xs font-semibold uppercase border-2 border-white hover:opacity-90 w-72"
            >
              <a href="https://wa.me/60192288230"
                ><i class="pr-2 fa-solid fa-angles-right"></i>Whatsapp Us
                Now..</a
              >
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- sub section-2  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/sub-section-2.jpg')"
    >
      <div class="container py-20 lg:py-36">
        <div class="text-left text-white textshadow">
          <h1 class="text-2xl font-medium md:text-3xl">An</h1>
          <h1 class="text-4xl font-bold md:text-6xl">ELEVATED</h1>
          <h1 class="text-2xl font-semibold md:text-3xl">WAY OF LIFE</h1>
        </div>
        <p class="pt-5 text-sm text-left text-white md:w-2/3">
          Here at Skyline KL, life is meant to be relished, and what better way
          than to do it with style and convenience. This is your escape from the
          bustling city - from the hectic and demanding schedules of a Kuala
          Lumpur urbanite. This is your sanctuary, your home.
        </p>
      </div>
    </div>
    <!-- end sub section-2  -->

    <!-- FACILITIES  -->
    <div id="facilities" class="py-10">
      <div class="container">
        <div class="text-sky-gold">
          <h1 class="text-3xl font-bold">FACILITIES</h1>
          <p class="pt-4">
            Come home to a curated collection of exclusive facilities that is
            designed to cater to all generations. Find your favorites on the
            vast facilities deck and spend quality time with friends and family.
            Whether it's working up a seat at the gym or peacefully indulging by
            the pool, there's something for everyone to enjoy.
          </p>
        </div>

        <!-- carousel  -->
        <div class="pt-4">
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false, dots: false },
              768: { items: 3, nav: false, dots: false },
            }"
          >
            <div v-for="image in facilityNew" :key="image">
              <img :src="image" alt="" />
            </div>
          </carousel>
        </div>

        <!-- facility podium  -->
        <div v-for="(item, i) in Level9" :key="i" class="pt-10">
          <h1 class="text-xl font-semibold md:text-2xl text-sky-blue">
            {{ item.level }}
            <span class="font-normal text-gray-600">{{ item.h1 }}</span>
          </h1>
          <div class="lg:flex">
            <img :src="item.left" alt="" class="lg:w-1/2" />
            <img :src="item.right" alt="" class="lg:w-1/2" />
          </div>
        </div>
      </div>
    </div>
    <!-- end FACILITIES  -->

    <!-- sub-whatsapp  -->
    <div class="py-10 bg-sky-gold">
      <div class="container">
        <div class="text-white md:flex md:items-center md:justify-center">
          <h1 class="text-xl font-bold md:pr-2">Want to Explore More?</h1>
          <div class="pt-4 md:pt-0">
            <h1
              class="py-3 mx-auto text-xs font-semibold uppercase border-2 border-white hover:opacity-90 w-72"
            >
              <a href="https://wa.me/60192288230"
                ><i class="pr-2 fa-solid fa-angles-right"></i>Whatsapp Us
                Now..</a
              >
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- personal space  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-space.jpg')"
    >
      <div class="container py-20 md:py-28 lg:py-36">
        <h1 class="text-2xl font-medium text-right text-white md:text-4xl">PERSONALISE</h1>
        <h1 class="text-3xl font-bold text-right text-white md:text-6xl">YOUR SPACE</h1>
      </div>
    </div>
    <div id="floor_plan" class="py-10">
      <div class="container">
        <div class="text-sky-gold">
          <h1 class="text-3xl font-bold">FLOOR PLANS</h1>
          <p class="pt-4">
            Savour the sweet nuances of urban living, with sleek home layouts
            that are wonderfully complemented by the beautiful KL cityscape.
            Skyline KL offers 5 fantastic layouts to choose from, with each
            design catering to varying lifestyles.
          </p>
        </div>
        <img src="/images/personal-space.jpg" alt="" class="w-full pt-6" />

        <!-- type house  -->
        <div>
          <div id="tabs" class="pt-6 md:flex md:flex-wrap lg:justify-center lg:max-w-4xl lg:mx-auto">
            <div v-for="(item, i) in personal_space" :key="i" class="w-full md:w-1/2 lg:w-1/5">
              <div
                v-bind:class="[tab === item.tab ? 'active' : '']"
                @click="tab = item.tab"
                class="transition duration-300 ease-linear cursor-pointer text-sky-gold hover:bg-sky-gold hover:text-white"
              >
                <h2 class="py-2 text-sm font-medium text-center">
                  {{ item.h1 }}
                </h2>
              </div>
            </div>
          </div>
          <!-- modal section -->
          <div v-for="(item, i) in type_space" :key="i">
            <div v-show="tab == item.tab" class="">
              <!-- image section -->
              <img :src="item.image" alt="" class="w-full lg:w-2/3 lg:mx-auto" />
            </div>
          </div>
        </div>
        <!-- carousel  -->
        <div class="pt-4 lg:max-w-3xl lg:mx-auto">
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false, dots: false },
              768: { items: 3, nav: false, dots: false },
            }"
          >
            <div v-for="image in facilityNew" :key="image">
              <img :src="image" alt="" />
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <!-- end personal space  -->

    <!-- WHERE IS SKYLINE KL  -->
    <!-- <div class="py-10 bg-gray-50">
      <div class="lg:container">
        <h1 class="text-2xl font-semibold text-sky-blue">
          Where is <span class="text-sky-gold"> Skyline KL</span>
        </h1>
        <div class="h-0.5 w-14 mx-auto my-8 bg-sky-blue"></div>
        <img src="/images/location.jpg" alt="" class="" />

        <div class="px-4 pt-6 lg:flex">
          <div
            v-for="(item, i) in location"
            :key="i"
            class="pb-6 lg:w-1/3 lg:px-1"
          >
            <h1 class="p-3 font-medium text-white bg-sky-blue">
              {{ item.h1 }}
            </h1>
            <ul class="p-4 text-left text-gray-600 bg-gray-200 lg:h-52">
              <li
                v-html="list"
                v-for="list in item.select"
                :key="list"
                class="pb-1"
              >
                {{ list }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <!-- subbanner 1  -->
    <!-- <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/sub-1.jpg')"
    >
      <div class="container py-14 lg:py-24">
        <a href="https://wa.me/60192288230">
          <h1
            class="p-3 text-2xl font-medium leading-tight md:w-2/3 lg:w-1/2 md:mx-auto opacity-90 hover:opacity-100 text-sky-blue bg-gradient-to-r from-sky-gold to-sky-yellow"
          >
            CONTACT US NOW!
          </h1>
        </a>
      </div>
    </div> -->
    <!-- SKYLINE GALLERY -->
    <div id="gallery" class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-sky-blue">
          Skyline KL<span class="text-sky-gold"> Gallery</span>
        </h1>
        <div class="h-0.5 w-14 mx-auto my-8 bg-sky-blue"></div>

        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 1, nav: false, dots: true },
            1000: { items: 2, nav: false, dots: true },
          }"
        >
          <div v-for="image in gallery" :key="image">
            <img :src="image" alt="" />
          </div>
        </carousel>
        <!-- <div
          class="flex flex-col pt-6 text-sm font-medium text-center text-gray-700 md:flex-row md:justify-center"
        >
          <a href="https://wa.me/60192288230" class="px-1 mb-4 md:mb-0">
            <h1
              class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
            >
              Book Appt To View
            </h1>
          </a>
          <a href="tel:+60192288230" class="px-1">
            <h1
              class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
            >
              Call Us Now
            </h1>
          </a>
        </div> -->
      </div>
    </div>

    <!-- sub-whatsapp  -->
    <div class="py-10 bg-sky-gold">
      <div class="container">
        <div class="text-white md:flex md:items-center md:justify-center">
          <h1 class="text-xl font-bold md:pr-2">What's Next?</h1>
          <div class="pt-4 md:pt-0">
            <h1
              class="py-3 mx-auto text-xs font-semibold uppercase border-2 border-white hover:opacity-90 w-72"
            >
              <a href="https://wa.me/60192288230"
                ><i class="pr-2 fa-solid fa-angles-right"></i>Whatsapp Us
                Now..</a
              >
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div id="contact" class="py-10">
      <div class="container">
        <div class="text-sky-gold">
          <h1 class="text-3xl font-bold">REGISTER NOW</h1>
          <p class="pt-2 text-xl font-medium">Get Your Exclusive Promotion </p>
        </div>
        <div class="pt-8 lg:flex">
          <div class="lg:w-1/2 lg:pr-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.850262571958!2d101.70999411502687!3d3.1342362541175186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc378d4cf4dc0d%3A0x178dfce2e40311f!2sSkyline%20KL!5e0!3m2!1sen!2smy!4v1650958958807!5m2!1sen!2smy"
              width="100%"
              height="350"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="pt-5 lg:w-1/2 lg:pt-0">
            <p class="text-sm font-medium lg:text-left text-sky-gold">
              Since you're here, why not REGISTER NOW to get EXCLUSIVE ACCESS to
              Limited Time Attractive Package Promo, Direct Developer's VVIP
              Price & Priority Unit Selection?
            </p>
            <EnquiryForm class="pt-6" />
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry form  -->
    <!-- <div class="py-10 bg-sky-blue">
      <div class="md:w-2/3 lg:w-1/2 md:mx-auto">
        <h1 class="pb-4 text-2xl font-semibold text-white">
          Contact us now for latest developer <br />
          <span class="text-sky-yellow"> pricing & layout plans</span>
        </h1>

        <div class="p-3 border-2 border-sky-yellow">
          <p class="py-5 text-white">Register your interest now</p>
          <EnquiryForm />
        </div>
      </div>
    </div> -->

    <!-- google map  -->
    <!-- <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.850262571958!2d101.70999411502687!3d3.1342362541175186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc378d4cf4dc0d%3A0x178dfce2e40311f!2sSkyline%20KL!5e0!3m2!1sen!2smy!4v1650958958807!5m2!1sen!2smy"
        width="100%"
        height="250"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div> -->
    <!-- footer  -->
    <div class="py-10 bg-sky-blue">
      <div class="container">
        <div>
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-2/3 mx-auto md:w-80"
          />
          <!-- <div
            class="flex flex-col pt-6 text-sm font-medium text-center text-gray-700 md:flex-row md:justify-center"
          >
            <a href="https://wa.me/60192288230" class="px-1 mb-4 md:mb-0">
              <h1
                class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
              >
                Book Appt To View
              </h1>
            </a>
            <a href="tel:+60192288230" class="px-1">
              <h1
                class="w-40 p-2 mx-auto rounded-lg bg-gradient-to-r from-sky-gold to-sky-yellow"
              >
                Call Us Now
              </h1>
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Copyright -->
    <div class="py-3 bg-white border-t border-gray-600">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-teeth-darkblue lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import Accordion from "@/components/Accordion.vue";
import WaBtn from "@/components/WaBtn.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";

export default {
  name: "Home",
  components: {
    MainHeader,
    MainBanner,
    carousel,
    Accordion,
    WaBtn,
    EnquiryForm,
  },
  data() {
    return {
      unique: [
        { image: "/images/icon-1.png", alt: "Stunning KL Skyline Views" },
        { image: "/images/icon-2.png", alt: "Transit Oriented Development " },
        { image: "/images/icon-3.png", alt: "100,000 sq.ft. Facility Area" },
        { image: "/images/icon-4.png", alt: "4 LRT/MRT Stops to KLCC" },
        {
          image: "/images/icon-5.png",
          alt: "2 LRT/MRT Stops to TRX Financial Hub",
        },
        { image: "/images/icon-6.png", alt: "Luxurious Yet Affordable" },
        { image: "/images/icon-7.png", alt: "Prime Urban Address" },
        { image: "/images/icon-8.png", alt: "Developed Surroundings" },
        { image: "/images/icon-9.png", alt: "Caters to All Generations" },
        { image: "/images/icon-10.png", alt: "5 Layouts to Choose From" },
      ],
      facility: [
        { h1: "2", p: "50m Olympic-length Swimming Pool " },
        { h1: "3", p: "Badminton Courts" },
        { h1: "4", p: "BBQ Deck & Pavilion across 3 levels facilities podium" },
        { h1: "5", p: "4,000 sqft Gym area" },
        { h1: "6", p: "660m Jogging Track" },
      ],
      facility_2: [
        {
          image: "/images/faci-1.jpg",
          alt: "Finely Crafted Spaces",
          p: "Skyline KL features a host of luxurious spaces that offers an air premium urban living. The common areas of Skyline KL are equally functional and aesthetically pleasing.",
        },
        {
          order: "order-2",
          image: "/images/faci-2.jpg",
          alt: "transit oriented development",
          p: "Skyline KL is well positioned for a life of discovery. Its direct link to the Pudu LRT Station allows owners easy access to all of the city's most frequented destinations.",
        },
        {
          image: "/images/faci-3.jpg",
          alt: "indulgence in the sky",
          p: "From the functional facilities podium, to the surreal sky dining or from the comforts of your very own home, a breathtaking backdrop is always there to accompany you.",
        },
      ],
      location: [
        {
          h1: "SHOPPING MALL / HYPERMARKET",
          select: [
            "1.1km to <b>Times Square & BBCC</b>",
            "2.2km to <b>The Exchange @ TRX</b>",
            "2.2km to <b>MyTown & IKEA Cheras</b>",
            "2.8km to <b>Sunway Velocity</b>",
            "3.0km to <b>Pavilion Shopping Centre</b>",
          ],
        },
        {
          h1: "MEDICAL",
          select: [
            "2.6km to <b>Sunway Medical Center Velocity</b>",
            "3.8km to <b>Prince Court Medical</b>",
            "4.2km to <b>Tong Shin Hospital</b>",
            "7.0km to <b>Gleneagles KL</b>",
          ],
        },
        {
          h1: "PUBLIC TRANSPORT",
          select: [
            "Direct Connected to <b>Pudu LRT Station</b>",
            "1 Station Interchange to <b>Chan Sow Lin MRT Station</b>",
            "1 Station Interchange to <b>Hang Tuah Monorail</b>",
          ],
        },
      ],
      gallery: [
        "/images/gallery-3.jpg",
        "/images/gallery-4.jpg",
        "/images/gallery-5.jpg",
        "/images/gallery-6.jpg",
        "/images/gallery-1.jpg",
        "/images/gallery-2.jpg",
      ],
      faq: [
        {
          q: "Is there any showroom available for Skyline KL?",
          a: "Yes, the showroom is available and it is opening every day, you can just send us <a href='https://wa.me/60192288230' style='color:#133059'><b>WhatsApp</b></a> or <a href='tel:+60192288230' style='color:#133059'><b>Call us</b></a>",
        },
        {
          q: "What is the size available?",
          a: "There is 1-4 rooms layout available for Skyline KL. Buyer can choose the right units that suits their needs.",
        },
        {
          q: "I am not sure if I am eligible for Loan Application",
          a: "For Skyline, we always put our customers first above everything else, helping client to secure loan for their desire property is our main priority. Our professional team will assist in the loan application process to making sure buying property with us is a smooth process",
        },
        {
          q: "Who is the developer for Skyline KL?",
          a: "LTS Skyline Sdn Bhd (a subsidiary company of TSLAW Land)",
        },
      ],
      tab: "tab-1",
      submenu: [
        { tab: "tab-1", icon: "fa-map-location-dot", sub: "Overall Map" },
        { tab: "tab-2", icon: "fa-bag-shopping", sub: "Shopping Mall" },
        { tab: "tab-3", icon: "fa-road", sub: "Road Connection" },
        { tab: "tab-4", icon: "fa-bus", sub: "Public Transport" },
      ],
      Location: [
        {
          tab: "tab-1",
          image: "/images/location-1.jpg",
        },
        {
          tab: "tab-2",
          image: "/images/location-2.jpg",
        },
        {
          tab: "tab-3",
          image: "/images/location-3.jpg",
        },
        {
          tab: "tab-4",
          image: "/images/location-4.jpg",
        },
      ],
      facilityNew: [
        "/images/facility-1.jpg",
        "/images/facility-2.jpg",
        "/images/facility-3.jpg",
        "/images/facility-4.jpg",
        "/images/facility-5.jpg",
      ],
      Level9: [
        {
          level: "LEVEL 9",
          h1: "FACILITIES PODIUM",
          left: "/images/podium-1_01.jpg",
          right: "/images/podium-1_02.jpg",
        },
        {
          level: "LEVEL 9",
          h1: "MEZZAININE FLOOR",
          left: "/images/podium-2_01.jpg",
          right: "/images/podium-2_02.jpg",
        },
        {
          level: "LEVEL 47",
          h1: "SKY DECK",
          left: "/images/podium-3_01.jpg",
          right: "/images/podium-3_02.jpg",
        },
      ],
      personal_space: [
        { tab: "tab-1", h1: "Type A" },
        { tab: "tab-2", h1: "Type B" },
        { tab: "tab-3", h1: "Type C" },
        { tab: "tab-4", h1: "Type D" },
        { tab: "tab-5", h1: "Type E" },
      ],
      type_space: [
        { tab: "tab-1", image: "/images/type-1.jpg" },
        { tab: "tab-2", image: "/images/type-2.jpg" },
        { tab: "tab-3", image: "/images/type-3.jpg" },
        { tab: "tab-4", image: "/images/type-4.jpg" },
        { tab: "tab-5", image: "/images/type-5.jpg" },
      ],
    };
  },
};
</script>

<style>
.textshadow {
  text-shadow: 2px 2px 2px #000000e3;
}
.overlay {
  position: absolute;
  top: 25%;
  /* left: 10%; */
  /* transform: translate(-20%, -50%); */
}
#tabs .active {
  background-color: #b7984b;
  color: #fff;
}

@media (min-width:1024px) {
  .overlay {
  left: 3%;
}
@media (min-width:1280px){
  .overlay {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
}
}
</style>
