<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <!-- navigation  -->
    <div class="container py-5">
      <!-- <h1 class="text-sm text-right text-white">
        <a href="tel:+60192288230">
          <i class="pr-2 fa-solid fa-mobile-screen"></i>019-228 8230
        </a>
      </h1> -->

      <div class="flex items-center justify-between md:justify-start">
        <div class="w-3/4 md:w-1/4 md:hidden">
          <img src="/images/main-logo.png" alt="" class="w-44 lg:w-52" />
        </div>
        <!-- mobile nav -->
        <div class="flex items-start justify-end w-1/4 md:hidden">
          <div
            @click="opened = !opened"
            class="flex items-center p-2 mt-2 cursor-pointer"
          >
            <h1 class="pr-2 text-sm text-white">MENU</h1>
            <div>
              <svg
                class="w-6 h-6 text-white fill-current"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- desktop nav -->
      <div class="hidden pt-5 lg:max-w-4xl lg:mx-auto md:block">
        <ul class="flex items-center justify-between text-sm text-white lg:text-base">
          <li
            class="transition duration-300 ease-linear cursor-pointer hover:text-sky-yellow"
          >
          <a href="/" v-scroll-to="{ el: '', duration: 1500 }">
            Home</a>
          </li>
          <li
            class="transition duration-300 ease-linear cursor-pointer hover:text-sky-yellow"
          >
          <a href="#" v-scroll-to="{ el: '#location', duration: 1500 }">
            Location</a>
          </li>
          <li
            class="transition duration-300 ease-linear cursor-pointer hover:text-sky-yellow"
          >
          <a href="#" v-scroll-to="{ el: '#facilities', duration: 1500 }">
            Facilities</a>
          </li>
          <li class="mx-5">
            <img src="/images/main-logo.png" alt="" class="w-48 mx-auto" />
          </li>
          <li
            class="transition duration-300 ease-linear cursor-pointer hover:text-sky-yellow"
          >
          <a href="#" v-scroll-to="{ el: '#floor_plan', duration: 1500 }">
            Floor Plans</a>
          </li>
          <li
            class="transition duration-300 ease-linear cursor-pointer hover:text-sky-yellow"
          >
          <a href="#" v-scroll-to="{ el: '#gallery', duration: 1500 }">
            Photo Gallery</a>
          </li>
          <li
            class="transition duration-300 ease-linear cursor-pointer hover:text-sky-yellow"
          >
          <a href="#" v-scroll-to="{ el: '#contact', duration: 1500 }">
            Contact Us</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- overlay mobile nav -->
    <div v-show="opened" class="">
      <div class="font-medium text-center text-white">
        <div>
          <a
            href="#"
            v-scroll-to="{ el: '#location', duration: 1500 }"
            class=""
          >
            <h1 class="py-2 hover:text-sky-yellow">Location</h1>
          </a>
        </div>
        <div>
          <a href="#" v-scroll-to="{ el: '#facilities', duration: 1500 }" class="">
            <h1 class="py-2 hover:text-sky-yellow">Facilities</h1>
          </a>
        </div>
        <div>
          <a href="#" v-scroll-to="{ el: '#floor_plan', duration: 1500 }" class="">
            <h1 class="py-2 hover:text-sky-yellow">Floor Plans</h1>
          </a>
        </div>
        <div>
          <a href="#" v-scroll-to="{ el: '#gallery', duration: 1500 }" class="">
            <h1 class="py-2 hover:text-sky-yellow">Photo Gallery</h1>
          </a>
        </div>
        <div>
          <a href="#" v-scroll-to="{ el: '#contact', duration: 1500 }" class="">
            <h1 class="py-2 hover:text-sky-yellow">Contact Us</h1>
          </a>
        </div>
      </div>
    </div>
    <!-- end navigation  -->

    <!-- banner  -->
    <div class="container py-20 md:py-24 lg:flex lg:items-center">
      <div class="text-white lg:text-left lg:w-1/2">
        <h1
          class="pb-2 text-5xl font-bold text-white textshadow md:text-6xl"
        >
          INVEST IN THE SOUL
        </h1>
        <h1 class="w-auto text-lg md:text-4xl textshadow">OF KUALA LUMPUR</h1>
        <div
          class="pt-6 text-sm text-center textshadow lg:text-base md:text-center lg:text-left"
        >
          <p>
            Welcome to ideal urban living, where everyday experiences are
            elevated to greater standards and everywhere that’s somewhere is
            well connected to your home.
          </p>

          <p class="pt-6 text-xl font-bold text-center lg:text-left text-sky-yellow">
            A Most Astute Investment
          </p>
        </div>
        <div class="pt-6">
          <h1
            class="p-3 mx-auto text-lg font-medium text-center transition duration-300 ease-linear rounded-lg lg:w-96 w-80 md:mx-auto lg:mx-0 hover:bg-sky-yellow bg-sky-gold"
          >
            <a href="tel:+60192288230" class="lg:inline-flex lg:items-center "
              ><i class="hidden pr-2 lg:block fa-solid fa-calendar-check"></i>
              Make An Appointment Today
            </a>
            <p class="text-sm font-light">- Get your exclusive promotion -</p>
          </h1>
        </div>
        <div class="pt-10 text-center text-white animate-bounce">
          <h1 class="font-medium textshadow">Scroll Down</h1>
          <i class="text-xl fa-solid fa-arrow-down"></i>
        </div>
      </div>
      <div class="pt-6 lg:w-1/2">
        <img src="/images/banner-side.jpg" alt="" class="shadow-xl md:w-2/3 lg:w-full md:mx-auto" />
      </div>
    </div>
    <!-- end banner  -->
  </div>
</template>

<script>
import EnquiryForm from "@/components/EnquiryForm.vue";
export default {
  components: {
    EnquiryForm,
  },
  data() {
    return {
      opened: false,
    };
  },
  // props: {
  //   hideEnquiry: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
};
</script>

<style>
</style>