<template>
  <form @submit.prevent="submit" class="mx-auto space-y-4 text-left">
    <div>
      <div class="border border-sky-gold rounded flex flex-col py-1.5 px-2 bg-white">
        <label for="name" class="text-sm text-sky-gold">Name <span class="text-gray-400">(Required)</span></label>
        <input v-model="fields.name" id="name" type="text" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.name" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <div class="border border-sky-gold rounded flex flex-col py-1.5 px-2 bg-white">
        <label for="email" class="text-sm text-sky-gold">E-Mail <span class="text-gray-400">(Required)</span></label>
        <input v-model="fields.email" id="email" type="email" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.email" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <div class="border border-sky-gold rounded flex flex-col py-1.5 px-2 bg-white">
        <label for="contact_number" class="text-sm text-sky-gold"
          >Contact Number <span class="text-gray-400">(Required)</span></label
        >
        <input v-model="fields.contact" id="contact_number" type="tel" class="text-gray-600 focus:outline-none" />
      </div>
      <InputError :field="errors.contact" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <div class="border border-sky-gold rounded flex flex-col py-1.5 px-2 bg-white">
        <label for="message" class="text-sm text-sky-gold">Message</label>
        <textarea
          v-model="fields.message"
          id="message"
          rows="5"
          type="text"
          class="h-auto p-0 px-0 m-0 font-sans text-base text-gray-600 border-0 focus:outline-none"
        ></textarea>
      </div>
      <InputError :field="errors.message" class="text-sm font-medium text-red-600" />
    </div>
    <div>
      <button
        v-text="busy ? 'Submitting...' : 'Submit Enquiry'"
        type="submit"
        class="w-full py-2 text-lg text-white transition border-none rounded bg-gradient-to-r from-sky-gold to-sky-yellow opacity-90 hover:opacity-100 disabled:cursor-wait disabled:opacity-50"
      ></button>
    </div>
  </form>
</template>

<script>
import hapiMixins from "@juno0424/vue2-hapi";
import { InputError } from "@juno0424/vue2-hapi-inputs";

export default {
  mixins: [hapiMixins],
  components: { InputError },
  data() {
    return {
      endpoint: "https://hapiform.sg/api/dc36161e-b5e1-45ea-abc9-b7b2bc9934d8",
      redirectTo: "/thank-you",
    };
  },
};
</script>
