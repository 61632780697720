<template>
  <div class="py-3 bg-white">
    <header class="container">
      <div class="md:flex md:justify-between md:items-center">
        <img
          src="/images/main-logo.png"
          alt="Skyline Kuala Lumpur"
          class="mx-auto w-52 md:mx-0"
        />
        <div>
          <div class="">
            <a href="tel:+60192288230">
            <h1 class="p-2 mx-auto font-medium text-white rounded-md bg-gradient-to-r from-sky-gold to-sky-yellow w-52">Call Us Now</h1>
            </a>
          </div>
        </div>
      </div>
    </header>

    <!-- header -->
    <!-- <header class="container py-5">
      <div class="md:flex md:justify-between md:items-center">
        <img
          src="/images/main-logo.png"
          alt="Skyline Kuala Lumpur"
          class="mx-auto w-52 md:mx-0"
        />
        <div>
          <div class="pt-3">
            <a href="tel:+60192288230">
              <h1
                class="p-2 mx-auto text-lg font-medium text-white transition duration-300 ease-linear rounded-lg textshadow hover:bg-sky-gold w-52"
              >
                <i class="pr-2 fa-solid fa-mobile-screen"></i>019-228 8230
              </h1>
            </a>
          </div>
        </div>
      </div>
    </header> -->
    <!-- end header  -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>